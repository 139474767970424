<template>
  <div class="main-body">
    <div class="page-attention">
      <p>删除的商品可以再回收站恢复。</p>
      <p>回收站商品删除后将不可恢复，请谨慎操作。</p>
    </div>
    <div class="table-search">
      <a-form :model="formState" layout="inline" :label-col="labelCol" autocomplete="off" @submit="handleRefresh">
        <a-form-item label="商品名称">
          <a-input v-model:value="formState.search_text" class="search-item" placeholder="请输入商品名称" />
        </a-form-item>
        <a-form-item label="商品编号">
          <a-input v-model:value="formState.sku_no" class="search-item" placeholder="请输入商品编号" />
        </a-form-item>
        <a-form-item label="商品分类">
          <a-select v-model:value="formState.category_id" class="search-item" placeholder="请选择">
            <a-select-option value="0">全部</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="商品类型">
          <a-select v-model:value="formState.goods_class" class="search-item" placeholder="请选择">
            <a-select-option value="0">全部</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <div class="search-handle">
        <a-button type="primary" class="handle-item" html-type="submit">搜索</a-button>
        <a-button html-type="submit">重置</a-button>
      </div>
    </div>

    <div class="batch-handle">
      <a-button size="small">批量删除</a-button>
      <a-button size="small">批量恢复</a-button>
    </div>

    <a-table :row-selection="rowSelection" :dataSource="dataSource" :columns="columns">
      <template #bodyCell="{ column,record }">
        <template v-if="column.dataIndex === 'goods_name'">
          <div class="goods-list-info">
            <span class="goods-list-image">
              <a-image :width="50" :src="record.goods_image" />
            </span>
            <span class="goods-name">{{record.goods_name}}</span>
          </div>

        </template>
        <template v-if="column.dataIndex === 'sort'">
          <a-input-number v-model:value="record.sort" :min="0" :max="100000" />
        </template>
        <template v-if="column.dataIndex === 'goods_state'">
          <span v-if="record.goods_state == 0">仓库中</span>
          <span v-else-if="record.goods_state == 1">销售中</span>
        </template>
        <template v-if="column.dataIndex === 'action'">
          <a-button type="link" size="small">恢复</a-button>
        </template>
      </template>
      <template #expandedRowRender="{ record }">
        <div class="goods-sku-box">
          <div class="goods-sku-item" v-for="item in 6" :key="item">
            <a-row>
              <a-col :span="6">
                <a-image :width="50" :src="record.goods_image" />
              </a-col>
              <a-col :span="18">
                <div class="sku-name">{{record.goods_name}}</div>
                <div class="sku-param-item">
                  <span>库存：{{record.goods_stock}}</span>
                  <span>销量：{{record.sale_num}}</span>
                </div>
                <div>原价：{{record.sale_num}}</div>
              </a-col>
            </a-row>
          </div>
        </div>
      </template>
    </a-table>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";

const columns = [
  {
    title: "商品信息",
    dataIndex: "goods_name",
    key: "goods_name",
    width: "30%",
  },
  {
    title: "价格",
    dataIndex: "price",
    key: "price",
    width: "10%",
  },
  {
    title: "库存",
    dataIndex: "goods_stock",
    key: "goods_stock",
    width: "10%",
  },
  {
    title: "销量",
    dataIndex: "sale_num",
    key: "sale_num",
    width: "5%",
  },
  {
    title: "排序",
    dataIndex: "sort",
    key: "sort",
    width: "5%",
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
    key: "create_time",
    width: "10%",
  },
  {
    title: "状态",
    dataIndex: "goods_state",
    key: "goods_state",
    width: "10%",
  },
  {
    title: "操作",
    dataIndex: "action",
    key: "action",
    width: "20%",
  },
];

const dataSource = [
  {
    key: 321,
    goods_id: 312,
    goods_name:
      "Apple iPhone 14 支持移动联通电信5G 双卡 （ 需自提现场激活） 128GB 标配 午夜色烨嵘轻奢沙发YR-49",
    site_id: 1,
    site_name: "",
    goods_image:
      "https://oss.cdjbz.com/upload/1/common/images/20220920/20220920020605166365396598080.jpg",
    goods_state: 1,
    price: "20838.00",
    goods_stock: 35,
    goods_stock_alarm: 0,
    create_time: 1663654236,
    sale_num: 0,
    is_virtual: 0,
    goods_class: 1,
    is_fenxiao: 0,
    fenxiao_type: 1,
    promotion_addon: "",
    sku_id: 1295,
    is_consume_discount: 0,
    discount_config: 0,
    discount_method: "",
    sort: 100,
    label_id: 0,
    is_delete: 0,
    label_name: "",
  },
  {
    key: 311,
    goods_id: 311,
    goods_name: "烨嵘轻奢沙发YR-43",
    site_id: 1,
    site_name: "",
    goods_image:
      "https://oss.cdjbz.com/upload/1/common/images/20220920/20220920020107166365366797713.jpg",
    goods_state: 1,
    price: "18880.00",
    goods_stock: 27,
    goods_stock_alarm: 0,
    create_time: 1663653918,
    sale_num: 0,
    is_virtual: 0,
    goods_class: 1,
    is_fenxiao: 0,
    fenxiao_type: 1,
    promotion_addon: "",
    sku_id: 1293,
    is_consume_discount: 0,
    discount_config: 0,
    discount_method: "",
    sort: 100,
    label_id: 0,
    is_delete: 0,
    label_name: "",
  },
  {
    key: 310,
    goods_id: 310,
    goods_name: "烨嵘轻奢沙发YR-39",
    site_id: 1,
    site_name: "",
    goods_image:
      "https://oss.cdjbz.com/upload/1/common/images/20220920/20220920015515166365331547187.jpg",
    goods_state: 1,
    price: "18880.00",
    goods_stock: 30,
    goods_stock_alarm: 0,
    create_time: 1663653545,
    sale_num: 0,
    is_virtual: 0,
    goods_class: 1,
    is_fenxiao: 0,
    fenxiao_type: 1,
    promotion_addon: "",
    sku_id: 1291,
    is_consume_discount: 0,
    discount_config: 0,
    discount_method: "",
    sort: 100,
    label_id: 0,
    is_delete: 0,
    label_name: "",
  },
];

export default defineComponent({
  components: {
    // PlusOutlined,
  },
  setup() {
    const formState = reactive({
      order_sn: "",
      order_type: "0",
    });

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          "selectedRows: ",
          selectedRows
        );
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    //刷新订单列表
    const handleRefresh = () => {};

    const router = useRouter();

    const addGoods = () => {
      router.push("/client/app/create");
    };

    // 初始化获取左侧导航
    onMounted(() => {});

    return {
      formState,
      addGoods,
      handleRefresh,
      labelCol: {
        style: {
          width: "100px",
        },
      },
      columns,
      dataSource,
      rowSelection,
    };
  },
});
</script>
<style lang="less">
.goods-list-info {
  display: flex;
  .goods-name {
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-break: break-all;
    margin-left: 10px;
  }
}

.batch-handle {
  margin-bottom: 16px;
  button {
    margin-right: 20px;
  }
}
.goods-sku-box {
  display: flex;
  flex-flow: wrap;
  margin-left: 76px;
  .goods-sku-item {
    width: 300px;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid #efefef;
    .sku-name {
      -webkit-line-clamp: 2;
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: normal;
      word-break: break-all;
    }
    .sku-param-item {
      span {
        margin-right: 10px;
      }
    }
  }
}
</style>